<template>
  <v-card
    style="margin-right: auto; margin-left: auto; display: flex; flex-direction: column; justify-content: middle; max-width: 600px;"
    class="justify-center text-center"
    height="100%"
    width="90%"
    elevation="0"
    v-if="showPrompt"
  >
    <v-form ref="form" v-model="form">
      <v-card-title style="font-family: 'Exo 2', sans-serif; word-break: normal; font-size: 18px; line-height: 20px; font-weight:600;"> {{ textPrompt }} </v-card-title>
      <v-textarea
        solo
        name=""
        v-model="text"
        rows="12"
        label="Respond here..."
        :rules="[
          (v) =>
            (v || '').length <= 500 ||
            'Response must be 500 characters or less',
        ]"
        counter="500"
        class="mx-4"
        background-color="#F3F1F7"
        no-resize
        style="font-family: 'Exo', sans-serif; font-size: 18px; font-weight: 400; color: black;"
      ></v-textarea>
    </v-form>
    <v-btn 
        class="ma-4"
        rounded 
        dark 
        color="#11D18C"
        style="font-family: 'Exo 2', sans-serif; text-transform: unset;" 
        @click="submit" 
        :disabled="!form || !isActive">
        Submit question
    </v-btn>
    <v-card-text style="font-size:10px; line-height:15px;">Please be respectful in your response.</v-card-text>
    
  </v-card>

  <v-card
    style="margin-right: auto; margin-left: auto; margin-top: 40px;"
    class="justify-center text-center"
    width="90%"
    elevation="0"
    v-else-if="loading"
  >
  <v-progress-circular
      color="#11D18C"
      indeterminate
      style="margin-top: 200px;"
    ></v-progress-circular>
    <v-card-text class="text-center" style="font-family: 'Exo 2', sans-serif; font-size:18px; font-weight: 600;">Waiting for Assistant</v-card-text>
  </v-card>
  
  <v-card
    style="margin-right: auto; margin-left: auto; margin-top: 40px;"
    class="justify-center text-center"
    width="90%"
    elevation="0"
    v-else
  >
    <!--<v-card-text class="white-text text-body-1 text-left">Your question:</v-card-text>
    <v-card-text class="white-text text-body-1 text-left">{{ textPrompt }}</v-card-text>
    <v-card-text class="white-text text-body-1 text-justify">{{ aiResponse }}</v-card-text>-->
    <v-card-text class="white-text text-left" style="font-family: 'Exo 2', sans-serif; word-break: normal; font-size: 20px; font-weight:600; color: black; padding-bottom: 5px;">Your question:</v-card-text>
    <v-card-text class="white-text text-left" style="font-family: 'Exo', sans-serif; font-size: 18px; font-weight: 400; color: black; padding-top: 5px;">{{ text }}</v-card-text>
    <div class="chat-box flex-none ml-3 mt-3 mr-3">                        
      <v-card-text class="pa-2 text-left">                                             
        <span style="font-family: 'Exo 2', sans-serif; font-size: 14px; font-weight: 600; line-height: 16px;">"{{ aiResponse }}"</span>
      </v-card-text>    
    </div>
    <div class="ml-3 chat-tail"></div>
    <div class="icon-group text-left">   
      <v-card-text class="pa-2 text-left">
        <v-icon
        medium
        color="black"
        >
          mdi-school
        </v-icon>                                             
        <span style="font-family: 'Exo 2', sans-serif; font-size: 14px; font-weight: 600; line-height: 16px;"> TA Response</span>
      </v-card-text>
    </div>
    <v-btn 
      class="ma-4"
      rounded 
      dark 
      color="#11D18C"
      style="font-family: 'Exo 2', sans-serif; text-transform: unset;"
      @click="reset"
      >
      Submit another question
    </v-btn>
  </v-card>
</template>

<script>
import axios from "axios";
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';

export default {
  name: "SubmitCompliment",
  data: () => ({
    text: "",
    form: false,
    clientData: {},
    textPrompt: "",
    clientId: "",
    isActive: false,
    complimentId: "",
    aiResponse: "default",
    showPrompt: true,
    loading: false
  }),
  methods: {
    verifyMobile() {
      if (
        window.navigator.userAgent.match(/Android/i) == null &&
        window.navigator.userAgent.match(/iPhone/i) == null &&
        window.navigator.userAgent.match(/Windows Phone/i) == null
      ) {
        this.$router.push("error");
      }
    },

    async submit() {
      const self = this;
      function checkThreadStatus(clientId, threadId) {
        var fetchDataObj = {codeId: clientId, complimentId: threadId}
          var fetchDataString = JSON.stringify(fetchDataObj);
          console.log(fetchDataString);

          var requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: fetchDataString
                    }

          fetch("https://laera-backend-9ec30f027a44.herokuapp.com/status", requestOptions)
            .then(function(response) {          
              return response.json();
            }).then(function(data){
              //if in_progress run it again
              if(data.status == "in_progress"){
                self.loading = true;
                self.showPrompt = false;

              checkThreadStatus(clientId, threadId);
              } else if (data.status == "completed") {
                //show response message
                self.loading = false;
                self.aiResponse = "";
                var responseMessages = data.messages;
                console.log(responseMessages);
                responseMessages.forEach((paragraph) => {
                  self.aiResponse += paragraph + "\n";
                });
              } else {
                //show failure message
                self.loading = false;
                self.aiResponse = "Something went wrong. Please try again.";
              }
            });
      }
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();
      data.append("codeId", this.clientId);
      data.append("text", this.text);
      data.append("textQuestion", this.textPrompt);
      data.append("requestId", 1234);

      var config = {
        method: "post",      
        url: "https://laera-backend-9ec30f027a44.herokuapp.com/compliment",
        headers: {
          ...data.getHeaders,
        },
        data: data,
        clientId: this.clientId
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          checkThreadStatus(config.clientId, response.data.id);
             
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async getClientDetails() {
      try {
        const response = await axios.get(
          "https://laera-backend-9ec30f027a44.herokuapp.com/codes/simple/" + this.clientId          
        );
        this.isActive = response.data.isActive;
        this.textPrompt = response.data.textQuestion;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    reset(){
      this.aiResponse = "";
      this.loading = "false";
      this.showPrompt = "true";
    },
  },
  

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.clientId = urlParams.get("id"); // Extracting the clientId from the id query parameter

    if (!this.clientId) {
      this.$router.push("/error");
      return;
    }

    //this.verifyMobile();
    this.getClientDetails();
  },
  computed: {
    complimentData() {
      return this.$store.getters.complimentData;
    }
  },
};
</script>
<style>
.chat-box {
  background-color: #F3F1F7;
  border-radius: 5px;
}
.chat-tail {
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #F3F1F7;
    margin-top: -20px;
}
</style>